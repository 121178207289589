export function useFormattedText() {
  const { n } = useI18n()
  const storeStore = useStoreStore()

  const getMoneyFormattedText = (amount: number, withDecimal: boolean = true) => {
    const amountWithRate = +amount * storeStore.currencyRate
    return n(amountWithRate, {
      key: withDecimal ? 'currency' : 'currencyWithoutDecimal',
      currency: storeStore.currencyCode
    })
  }

  const getRewardFormattedText = (rewardText: string, amount: number, key: string) => {
    return rewardText.replace(`{{${key}}}`, getMoneyFormattedText(amount))
  }

  return { getMoneyFormattedText, getRewardFormattedText }
}
