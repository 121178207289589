import { useQuery } from '@tanstack/vue-query'
import type { GenericApiError, ReferralError } from '~/types/apiError'
import type { Referral, ReferralForm, ReferralProgram, ReferralFriend } from '~/types/referral'

export const useStoreReferral = defineStore('referral', () => {
  const sendReferral = async (referralForm: ReferralForm): Promise<unknown | GenericApiError> => {
    const res = await useNuxtApp().$api(
      `/api/creator/company/${referralForm.companyId}/referral-referral/send-email/`,
      {
        method: 'POST',
        body: { email: referralForm.email },
        onResponseError({ response }) {
          throw response._data as GenericApiError
        }
      }
    )
    return res
  }

  const getReferralProgram = (companyId: number) => {
    return useQuery({
      queryKey: ['getReferralProgram', companyId],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/company/${companyId}/referral-program/`)) as ReferralProgram
      },
      staleTime: 1800000 // 30 minutes
    })
  }

  const getReferrals = (companyId: number) => {
    return useQuery({
      queryKey: ['listReferrals', companyId],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/company/${companyId}/referral-referral/`)) as Referral[]
      },
      staleTime: 1800000 // 30 minutes
    })
  }

  const getFriendReferral = async (companyId: number, creatorId: number, email?: string) => {
    const res = await useNuxtApp().$api(`/api/creator/company/${companyId}/referral-referral/`, {
      method: 'POST',
      body: { company: companyId, creator: creatorId, godson_email: email },
      onResponseError({ response }) {
        throw response._data as ReferralError
      }
    })
    return res as ReferralFriend
  }

  return { sendReferral, getReferrals, getReferralProgram, getFriendReferral }
})
